import { Link } from 'react-router-dom';
import { HomeFilled} from '@ant-design/icons';

const itemsMenu = [
    {
      label: (<a href="https://evalquiz.com"> evalquiz</a>
      ),
      key: 'evalquiz',
      icon: <HomeFilled />,
    },
      {
        label: (  <Link to="/presentation">
            Les challenges
            </Link>
        ),
        key: 'nosjeux',
        children: [
         
              {
                label: (  <Link to="/presmot">
                Jeux de lettres de de mots
                </Link>
            ),
                key: 'mots',
              },
              {
                label: (  <Link to="/presorthographe">
                Jeux sur l'orthographe
                </Link>
            ),
                key: 'orthographe',
              },
              
              {
                label: (  <Link to="/presconjugaison">
                Jeux sur la conjugaison
                </Link>
            ),
                key: 'conjugaison',
              },


              {
                label: (  <Link to="/presvocabulaire">
                Jeux sur le vocabulaire
                </Link>
            ),
                key: 'vocabulaire',
              },
              {
                label: (  <Link to="/presentationcerebral">
                Jeux de logique</Link>
            ),
                key: 'logique',
              },
              {
                label: (  <Link to="/presreflexion">
                Jeux de réflexion</Link>
            ),
                key: 'reflexion',
              },
              {
                label: (  <Link to="/presgeographie">
                Jeux sur la géographie</Link>
            ),
                key: 'geographie',
              },
              {
                label: (  <Link to="/presculture">
                Jeux sur la culture générale</Link>
            ),
                key: 'cultureGe',
              },
              {
                label: (  <Link to="/presfootball">
                Jeux sur le football</Link>
            ),
                key: 'football',
              },
         
        ],
        
      },
      {
        label: (   <a href="https://evalquiz.com/tests/logique">
        QI
       </a>
        ),
        key: 'logiqueQi'
      }, 
      {
        label: ( <a href="https://evalquiz.com/tests/culture">
        Quiz
       </a>
        ),
        key: 'culture'
      },
    

      
     {
        label: ( <Link to="/presagemental">
      Âge mental
       </Link>
        ),
        key: 'ageMental'
      },
    
    
   
     
  ];

  export {itemsMenu}