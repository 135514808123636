
import React, { Component, Suspense } from 'react';
import { ConfigProvider } from 'antd';

import ReactGA4 from "react-ga4";
import { router } from './Routeur';
import { RouterProvider,} from "react-router-dom";
import '../css/app.css'



export default class App extends Component {

  constructor() {
    super();
   
      
      ReactGA4.initialize("G-916D7SW5D6", { legacyDimensionMetric: false, gtagOptions: { send_page_view: true } })
  }

  

  render() {
    return (
      <div className="App">
    
        <ConfigProvider
    theme={{
      token: {
         fontSize: '16px',
      },
      components: {
        Menu: {
          colorItemBg: '#afe4e2',
        }}
    }}
  >
  
         <Suspense fallback={<div>En cours de chargement...</div>}>
          <RouterProvider router={router} >
            </RouterProvider>
            </Suspense>
        </ConfigProvider>
        <div className='margeHaut45 centre'><small>evalquiz.com</small></div>
      </div>
      
    );
  }
}

