
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Helmet } from 'react-helmet';
import Ad from '../commun/AdSense';

export default class Presentation extends Component {

 
    render()
    {

        return <div>
             <Helmet>
                <title>Les challenges, des jeux de réflexion et de logique pour adultes et enfants gratuits </title>
                <meta name="description" content="Des challenges pour se détendre de façon intelligente avec des jeux de réflexion gratuits en ligne,  de nombreux thèmes sont proposés comme la géographie, la culture générale, l'orthographe..." />
            </Helmet>
            <h1 className="centre">Les challenges</h1>
            <p>Venez essayer nos petits jeux et faites fonctionner vos méninges. Tous nos jeux sont très faciles d'accès, on peut y jouer de 10 à 110 ans, ils sont gratuits et ne nécessitent aucune inscription. On vous demande simplement votre prénom pour établir un classement. </p>
      
            <Ad></Ad>
           <Row>
           
            <Col sm={24} md={24}><div className="texteMot centre padding3">
           <Link  to={"/presconjugaison"}><div className="titreJeu">🔍 Connaissez-vous les conjugaisons françaises ? ✏️ </div></Link>
                <p>Préparez-vous à tester vos compétences verbales.  Ce quiz offre une expérience divertissante et éducative pour tous et permet de revoir les bases de la grammaire tout en s'amusant.</p>
                <p>Êtes-vous prêt pour le défi ?  Que l'aventure verbale commence ! 🏆💡</p>

                     
             
              </div>
              </Col>
              
            <Row>
            <Col sm={24} md={12}><div className="texteMot centre padding3">
           <Link  to={"/presvocabulaire"}><div className="titreJeu">📖 Testez votre vocabulaire ✏️ </div></Link>
                <p>Rejoignez la communauté des amoureux des mots et découvrez une nouvelle façon amusante d'apprendre et de jouer tout en enrichissant votre vocabulaire.</p>
                <p> Affûtez vos compétences linguistiques, relevez des défis stimulants et devenez le maître des mots. 🏆💡</p>

                     
             
              </div>
              </Col>
              
           <Col sm={24} md={12}><div className="texteMot centre padding3">
           <Link  to={"/presorthographe"}><div className="titreJeu">📚 Testez votre orthographe 📝</div></Link>
                <p>Êtes-vous prêt à mettre à l'épreuve vos connaissances en orthographe ? Notre tout nouveau défi de l'orthographe est là pour vous permettre de briller en maîtrisant la langue française.</p>
                <p>Rejoignez-nous dès aujourd'hui pour montrer que vous êtes prêt à dompter les subtilités de notre belle langue. 🇫🇷💪</p>

                     
             
              </div>
              </Col>
              
               </Row>
           <Col sm={24} md={24}><div className="texteMot centre padding3">
                <Link  to={"/presreflexion"}> <div className="titreJeu">🌀 Les défis des jeux de réflexion 🔍</div></Link>
                <p>Prêt à mettre vos compétences en jeux de réflexion à l'épreuve ? Notre tout nouveau défi de jeux de réflexion est là pour vous offrir des heures de divertissement cérébral.</p>
                <p>Que vous soyez un as des chiffres, un stratège du démineur, ou un maître du picross, ce défi est fait pour vous. Préparez-vous à des moments de résolution de casse-tête captivants et à des victoires intellectuelles ! 🧠🏆

Rejoignez-nous dès aujourd'hui pour montrer que vous êtes prêt à relever tous les défis logiques qui se présentent à vous. 🎯💡</p>

              </div>
              </Col> 
              <Col sm={24} md={24}><div className="texteMot centre padding3">
             <Link to={"/presgeographie"}><div className="titreJeu">🌍 La fête de la géographie 🗺️</div></Link>
                <p>Êtes-vous prêt à mettre votre culture du monde à l'épreuve ? Notre tout nouveau défi de géographie vous invite à explorer la planète, de ses capitales exotiques aux drapeaux colorés qui la représentent.</p>
                <p>Que vous soyez un globe-trotter aguerri ou que vous souhaitiez simplement perfectionner vos connaissances, ce défi est fait pour vous. Préparez-vous à des moments de découverte palpitants et à des victoires géographiques ! 🌎🌟</p>
          
              </div>
              </Col> 
          
           <Col sm={24} md={24}><div className="texteMot centre padding3">
              <Link  to={"/presculture"}><div className="titreJeu">🧠 La fête de la culture générale 🧩</div></Link>
                <p>Le test ultime de la culture générale</p>
                <p>Seul les meilleurs pourront le réussir ! </p>
               
               
                
              </div>
              </Col> 
           
           <Col sm={24} md={12}><div className="texteMot centre padding3" >
                  <Link to={"/presagemental"}><div className="titreJeu">🎓 L'âge mental ⏳</div></Link>
                <p>Venez essayer notre test pour évaluer votre âge mental.</p>
                <p>Votre âge mental correspond-il à votre âge physique ? </p>
               
                <div className="justifierGauche">
                 <p>5 petits jeux pour calculer votre âge mental.</p>
                <ul>
                <li>Les ronds jaunes et rouges</li>
                <li>La mémoire des mots</li>  
                <li>Les carrés roses</li>
                <li>Le calcul mental</li>
                <li>Le Simon</li>
                </ul>
                </div>
             
              </div>
              </Col> 
              <Col sm={24} md={12}><div className="texteMot centre padding3">
                <a  href="https://anglais.evalquiz.com"> <div className="titreJeu">🗣️ 30 jours d'anglais 🏆</div></a>
                <p>Vous souhaitez revoir les bases de l'anglais ?</p>
                <p>Venez essayer notre programme pour se remettre à l'anglais.</p>
               
                <div className="justifierGauche">
                 <p>5 différents domaines pour revoir les bases :</p>
                <ul>
                <li>Vocabulaire anglais</li>
                <li>Grammaire anglaise</li>  
                <li>Prononciation anglaise</li>
                <li>QCM anglais</li>
                <li>Compréhension</li>
                </ul>
                </div>
              </div>
              </Col> 
               <Col sm={24} md={12}><div className="texteMot centre padding3">
              <Link to={"/presmot"}>  <div className="titreJeu">🕵️‍♂️ Le challenge des mots 📰</div></Link>
                <p>Venez essayer notre challenge de lettres et de mots qui comporte 99 étapes.</p>
                <p>Affrontez nos 8 petits jeux</p>
                <div className="justifierGauche">
                <ul>
                <li>Les lettres en désordre</li>
                <li>Motus revisité</li>
                
                <li>Le nouveau Boogle</li>
                <li>La chasse aux mots</li>
                <li>La phrase codée</li>
                <li>Les mots coupés</li>
                <li>Trouver les erreurs</li>
                <li>Les mots cachés</li></ul>
                </div>
              
              </div>
              </Col> 
              <Col sm={24} md={12}><div className="texteMot centre padding3">
               <Link  to={"/presentationcerebral"}>  <div className="titreJeu">🧩 Testez votre logique et votre réflexion ! 🎮</div></Link>
                <p>Venez essayer notre challenge de jeux cérébraux qui comporte 99 étapes.</p>
                <p>Affrontez nos 8 petits jeux</p>
                <div className="justifierGauche">
                <ul>
                <li>Binero</li>
                <li>Puzzle</li>        
                <li>Le circuit fermé</li>
                <li>L'énigme</li>
                <li>Fubuki</li>
                <li>Picross</li>
                <li>Points à relier</li>
                <li>Mastermind</li></ul>
                </div>

              </div>
              </Col> 
             
        </Row>
<Ad></Ad>
        </div>
    }
  
}